export const MuiIconButton = {
  styleOverrides: {
    sizeMedium: {
      '& svg': {
        width: 24,
        height: 24,
      },
    },
    sizeSmall: {
      '& svg': {
        width: 16,
        height: 16,
      },
    },
  },
  variants: [
    {
      props: {
        color: 'isabelline',
      },
      style: ({ theme }) => ({
        color: theme.palette.isabelline,
      }),
    },
    {
      props: {
        color: 'raisinBlack',
      },
      style: ({ theme }) => ({
        color: theme.palette.raisinBlack,
      }),
    },
    {
      props: {
        color: 'royalBlueDark',
      },
      style: ({ theme }) => ({
        color: theme.palette.royalBlueDark,
      }),
    },
    {
      props: {
        color: 'icterine',
      },
      style: ({ theme }) => ({
        color: theme.palette.icterine,
      }),
    },
    {
      props: {
        bgcolor: 'raisinBlack',
      },
      style: ({ theme }) => ({
        backgroundColor: `${theme.palette.raisinBlack} !important`,
      }),
    },
  ],
};
