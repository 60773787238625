import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import { useLocation, useNavigate } from 'react-router-dom';

import imgBubble1 from '../../assets/onboarding/bubble-1.png';
import imgBubble2 from '../../assets/onboarding/bubble-2.png';
import imgBubble3 from '../../assets/onboarding/bubble-3.png';
import imgOily1 from '../../assets/onboarding/oily-1.png';
import imgOily2 from '../../assets/onboarding/oily-2.png';

export const WalkthroughPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [step, setStep] = useState(0);

  const handleContinue = useCallback(() => {
    if (step < 2) {
      setStep(step + 1);
    } else {
      navigate('/onboarding/questions', {
        replace: true,
        state: {
          joinTelegramLink: location.state?.joinTelegramLink,
        },
      });
    }
  }, [step, navigate, location]);

  useEffect(() => {
    if (location.state?.joinTelegramLink) {
      return;
    }
    window.location.href = '/welcome';
  }, [location]);

  return (
    <Stack width="100%" minHeight="100vh" direction="column">
      <Stack
        flex={1}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        sx={{
          '& .carousel.carousel-slider': {
            height: '100%',
            '& .slider-wrapper': {
              height: '100%',
            },
            '& .slider': {
              height: '100%',
            },
            '& .slide': {
              width: '100%',
            },
          },
        }}
      >
        <Carousel
          emulateTouch
          showArrows={false}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          selectedItem={step}
          onChange={(index) => setStep(index)}
        >
          <Stack
            height="100%"
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              sx={{
                position: 'relative',
                overflow: 'visible',
              }}
            >
              <img
                alt="Oily"
                src={imgOily1}
                style={{
                  width: 206,
                  height: 'auto',
                }}
              />
              <img
                alt="Bubble"
                src={imgBubble1}
                style={{
                  width: 153,
                  height: 144,
                  position: 'absolute',
                  left: '50%',
                  top: -120,
                }}
              />
            </Box>
          </Stack>
          <Stack
            height="100%"
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              sx={{
                position: 'relative',
                overflow: 'visible',
              }}
            >
              <img
                alt="Oily"
                src={imgOily2}
                style={{
                  width: 206,
                  height: 'auto',
                }}
              />
              <img
                alt="Bubble"
                src={imgBubble2}
                style={{
                  width: 244,
                  height: 179,
                  position: 'absolute',
                  left: -50,
                  top: -150,
                }}
              />
            </Box>
          </Stack>
          <Stack
            height="100%"
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              sx={{
                position: 'relative',
                overflow: 'visible',
              }}
            >
              <img
                alt="Oily"
                src={imgOily2}
                style={{
                  width: 206,
                  height: 'auto',
                }}
              />
              <img
                alt="Bubble"
                src={imgBubble3}
                style={{
                  width: 244,
                  height: 149,
                  position: 'absolute',
                  right: -45,
                  top: -125,
                }}
              />
            </Box>
          </Stack>
        </Carousel>
      </Stack>
      <Stack direction="column" px={4} pb={4} mt={4}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb={3}
        >
          {[0, 1, 2].map((index) => (
            <Box
              key={index}
              sx={{
                mx: 0.5,
                height: 8,
                width: step === index ? 24 : 8,
                borderRadius: 4,
                transition: 'all 0.2s',
                bgcolor: 'beauBlue',
                opacity: step === index ? 1 : 0.5,
              }}
            />
          ))}
        </Stack>
        <Button variant="contained" color="icterine" onClick={handleContinue}>
          Continue
        </Button>
      </Stack>
    </Stack>
  );
};
