import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, ButtonBase, Modal, Stack, Typography } from '@mui/material';
import { useAuth as useNFIDAuth } from '@nfid/identitykit/react';
import { useDispatch } from 'react-redux';
import { apiConnectWallet } from '../../apis/wallet';

import imgXdc from '../../assets/networks/xdc.png';
import imgVechain from '../../assets/networks/vechain.png';
import imgNFID from '../../assets/networks/nfid.png';
import { setWallet } from '../../redux/wallet.slice';

const ConnectOption = ({ icon, name, onClick, ...props }) => {
  return (
    <ButtonBase
      sx={{
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'left',
        px: 2,
        py: 2,
        borderRadius: 2,
      }}
      onClick={onClick}
      {...props}
    >
      <img
        alt={name}
        src={icon}
        style={{
          width: 32,
          height: 32,
        }}
      />
      <Typography
        fontSize={16}
        fontWeight={500}
        lineHeight={1.25}
        color="raisinBlack"
        ml={2}
      >
        {name}
      </Typography>
    </ButtonBase>
  );
};

export const ConnectOptionsModal = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const nfidAuth = useNFIDAuth();

  const handleVechain = useCallback(async () => {
    await apiConnectWallet('vechain');
    onClose();
  }, [onClose]);

  const handleXdc = useCallback(async () => {
    await apiConnectWallet('xdc');
    onClose();
  }, [onClose]);

  const handleNFID = async () => {
    try {
      await nfidAuth.connect();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (nfidAuth.user) {
      if (nfidAuth.user.subAccount && nfidAuth.user.principal) {
        dispatch(
          setWallet({
            account: nfidAuth.user.principal.toText(),
            chainName: 'nfid',
            chainId: 999999,
          }),
        );
      }
    }
  }, [nfidAuth.user, dispatch]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
          Choose network
        </Typography>
        <Stack direction="column" mt={4}>
          <ConnectOption
            icon={imgVechain}
            name="Vechain"
            onClick={handleVechain}
          />
          <ConnectOption icon={imgXdc} name="XDC Network" onClick={handleXdc} />
          <ConnectOption
            icon={imgNFID}
            name="Internet Computer Protocol"
            onClick={handleNFID}
          />
        </Stack>
      </Box>
    </Modal>
  );
};

ConnectOption.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
};

ConnectOptionsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConnect: PropTypes.func,
};
