import React, { useCallback, useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import { Scaffold } from '../../components/layouts/Scaffold';
import {
  Box,
  Button,
  ButtonBase,
  IconButton,
  Modal,
  Slider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { XpGauge } from '../../components/core/XpGauge';
import { XpIndicator } from '../../components/core/XpIndicator';
import { ArrowLeftIcon, ClipboardCheckIcon, CopyIcon } from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { apiClaimReward } from '../../apis/redeem';
import { apiGetProfile } from '../../apis/profile';
import { updateAppStatus } from '../../redux/app.slice';

import { XpHistoryPart } from './partials/XpHistoryPart';
import { RedeemHistoryPart } from './partials/RedeemHistoryPart';

export const RedeemPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    xp: currentXp,
    ratio,
    level,
    lockedXp,
  } = useSelector((state) => state.profile);
  const { account } = useSelector((state) => state.wallet);
  const { isGlobalLoading } = useSelector((state) => state.app);
  const xp = currentXp - lockedXp;

  const queryClient = useQueryClient();

  const [tab, setTab] = useState('redeem');
  const [copied, setCopied] = useState(false);
  const [percentage, setPercentage] = useState(100);
  const [open, setOpen] = useState(false);

  const handleBack = useCallback(() => {
    navigate('/home');
  }, [navigate]);

  const handleCopyAddress = useCallback(() => {
    if (account) {
      copy(account);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [account]);

  const handleRedeem = useCallback(async () => {
    setOpen(false);
    if (!xp || xp <= 0 || isGlobalLoading || !ratio) {
      return;
    }

    dispatch(
      updateAppStatus({
        isGlobalLoading: true,
      }),
    );
    const result = await apiClaimReward({
      percentage: percentage,
    });
    if (result && result > 0) {
      enqueueSnackbar({
        message: `You have converted ${((xp * percentage) / 100).toFixed(0)} XP into ${Number(result).toFixed(2)} B3TR`,
        variant: 'success',
      });
      await queryClient.invalidateQueries('transactions');
    }
    dispatch(
      updateAppStatus({
        isGlobalLoading: false,
      }),
    );
  }, [xp, ratio, percentage, isGlobalLoading, dispatch, queryClient]);

  const handleChangeTab = useCallback(
    (target) => {
      return () => {
        if (tab !== target) {
          setTab(target);
        }
      };
    },
    [tab],
  );

  useEffect(() => {
    apiGetProfile({
      need_balance: true,
      need_ratio: true,
    });
  }, []);

  useEffect(() => {
    if (level < 3) {
      navigate('/home');
    }
  }, [level, navigate]);

  return (
    <Scaffold height="100vh" bgcolor="raisinBlack">
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pt={1}
          pb={2}
          minHeight={56}
        >
          <IconButton
            color="isabelline"
            size="small"
            sx={{ mr: 2 }}
            onClick={handleBack}
          >
            <ArrowLeftIcon />
          </IconButton>
          <XpIndicator onClick={() => setOpen(true)} />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Box px={3} mt={2}>
          <Typography
            fontSize={16}
            fontWeight={700}
            color="isabelline"
            lineHeight={1.75}
          >
            Current balance
          </Typography>
          <Box>
            <XpGauge
              xp={(((xp ?? 0) * percentage) / 100)?.toFixed(0) ?? '0'}
              max={xp?.toFixed(0) ?? '0'}
              iconProps={{
                width: 24,
                height: 24,
              }}
              xpProps={{
                fontSize: 32,
                lineHeight: 1.25,
                color: 'white',
              }}
              maxProps={{
                fontSize: 32,
                lineHeight: 1.25,
                color: 'shadowBlue',
              }}
            />
          </Box>
          <Typography fontSize={14} lineHeight={1.7} color="beauBlue">
            ~{' '}
            {(
              ((((xp ? xp : 0) * percentage) / 100) * (ratio ?? 1)) /
              100
            ).toFixed(3)}{' '}
            B3TR
          </Typography>
          <Typography fontSize={10} lineHeight={1.6} color="shadowBlue">
            ~{(1 / (ratio ? ratio / 100 : 1)).toLocaleString()} XP per 1 B3TR
          </Typography>
          {!xp ? (
            <ButtonBase
              sx={{
                width: '100%',
                px: 2,
                py: 1.5,
                borderRadius: 4,
                bgcolor: `${theme.palette.beauBlue}30`,
                flexDirection: 'column',
                boxSizing: 'border-box',
                mt: 3,
              }}
              component={NavLink}
              to="/challenges"
            >
              <Typography fontSize={22} lineHeight={1.5} color="white">
                You didn&apos;t earn any reward
              </Typography>
              <Typography fontSize={14} lineHeight={1.7} color="beauBlue">
                Head on to Challenges
              </Typography>
            </ButtonBase>
          ) : null}
          <Box mt={4} onClick={() => setOpen(true)}>
            <Button variant="contained" color="icterine" fullWidth>
              Redeem
            </Button>
          </Box>
          <Typography
            mt={4}
            fontSize={16}
            fontWeight={700}
            color="isabelline"
            lineHeight={1.75}
          >
            Your wallet address
          </Typography>
          <Stack mt={0.5} direction="row" alignItems="center">
            <Typography
              fontSize={14}
              lineHeight={1.7}
              color="isabelline"
              mr={1}
            >
              {account?.substr(0, 10)}...{account?.substr(-8, 8)}
            </Typography>
            <IconButton
              size="small"
              color="isabelline"
              onClick={handleCopyAddress}
            >
              {copied ? <ClipboardCheckIcon /> : <CopyIcon />}
            </IconButton>
          </Stack>
        </Box>
        <Box mt={3} mx={1}>
          <Stack
            direction="row"
            p={1}
            spacing={1}
            bgcolor="charcoal"
            borderRadius={2}
          >
            <ButtonBase
              sx={{
                flex: 1,
                py: '10px',
                borderRadius: 2,
                bgcolor: tab === 'redeem' ? 'raisinBlack' : 'transparent',
              }}
              onClick={handleChangeTab('redeem')}
            >
              <Typography color="isabelline" fontSize={14} fontWeight={500}>
                Redeem History
              </Typography>
            </ButtonBase>
            <ButtonBase
              sx={{
                flex: 1,
                py: '10px',
                borderRadius: 2,
                bgcolor: tab === 'xp' ? 'raisinBlack' : 'transparent',
              }}
              onClick={handleChangeTab('xp')}
            >
              <Typography color="isabelline" fontSize={14} fontWeight={500}>
                XPs History
              </Typography>
            </ButtonBase>
          </Stack>
        </Box>
        {tab === 'redeem' ? <RedeemHistoryPart /> : null}
        {tab === 'xp' ? <XpHistoryPart /> : null}
        <Modal open={open} onClose={() => setOpen(false)}>
          <Box>
            <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
              You&apos;re about to convert&nbsp;
              <b>{Number((xp * percentage) / 100).toFixed(0)}</b> XP into&nbsp;
              <b>
                {Number((((xp * percentage) / 100) * ratio) / 100).toFixed(2)}
              </b>
              &nbsp; B3TR
            </Typography>
            <Typography
              mt={3}
              fontSize={16}
              lineHeight={1.75}
              color="raisinBlack"
            >
              Set a value below to redeem your XP&apos;s for $B3TR:
            </Typography>
            <Box mt={6}>
              <Slider
                valueLabelDisplay="auto"
                value={percentage}
                onChange={(ev, value) => setPercentage(value)}
              />
            </Box>
            <Stack mt={11} direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="raisinBlack"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="raisinBlack"
                onClick={handleRedeem}
              >
                Continue
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Scaffold.Content>
    </Scaffold>
  );
};
