import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Modal, Stack, Typography, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ConnectOptionsModal } from '../../components/modals/ConnectOptionsModal';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppStatus } from '../../redux/app.slice';
import { apiLogin } from '../../apis/auth';
import { apiGetProfile } from '../../apis/profile';

import imgBrandBg from '../../assets/brands/brand-bg.png';
import imgBrandLogo from '../../assets/brands/brand-logo.png';

const LogoImage = styled('img')({
  marginTop: '20vh',
});

export const WelcomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { account, chainName } = useSelector((state) => state.wallet);

  const [modals, setModals] = useState({
    connectOptions: false,
    createWallet: false,
    verifyOtp: false,
  });

  const handleToggleModal = useCallback((key) => {
    setModals((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  }, []);

  const handleConnect = useCallback(() => {
    handleToggleModal('connectOptions');
  }, [handleToggleModal]);

  const handleCreateWallet = useCallback(() => {
    handleToggleModal('createWallet');
  }, [handleToggleModal]);

  useEffect(() => {
    if (!account || !chainName) {
      return;
    }

    dispatch(
      updateAppStatus({
        isGlobalLoading: true,
      }),
    );
    apiLogin({
      wallet_address: account,
      chain: chainName,
    }).then(async (response) => {
      if (response === 'verified') {
        await apiGetProfile({
          need_balance: true,
          need_ratio: true,
        });
        navigate('/home', {
          replace: true,
        });
      } else if (response && typeof response === 'string') {
        navigate('/onboarding/walkthrough', {
          replace: true,
          state: {
            joinTelegramLink: response,
          },
        });
      } else {
        enqueueSnackbar({
          message: 'Failed to login, please try again later',
          variant: 'error',
        });
      }
      dispatch(
        updateAppStatus({
          isGlobalLoading: false,
        }),
      );
    });
  }, [dispatch, navigate, account, chainName]);

  return (
    <Stack
      width="100%"
      minHeight="100vh"
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundImage: `url(${imgBrandBg})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
      }}
    >
      <LogoImage src={imgBrandLogo} alt="Logo" width={105} height={105} />
      <Typography mt={4} mb={2} color="isabelline" component="p" fontSize={18}>
        I have a wallet and want to get going
      </Typography>
      <Button variant="contained" color="icterine" onClick={handleConnect}>
        Connect
      </Button>
      <Typography color="isabelline" mt={4} mb={2} component="p" fontSize={18}>
        I don&apos;t have a wallet or an account yet
      </Typography>
      <LoadingButton
        variant="outlined"
        color="icterine"
        sx={{
          mb: 4,
        }}
        onClick={handleCreateWallet}
      >
        Create Wallet
      </LoadingButton>
      <ConnectOptionsModal
        open={modals.connectOptions}
        onClose={() => handleToggleModal('connectOptions')}
      />
      <Modal
        open={modals.createWallet}
        onClose={() => handleToggleModal('createWallet')}
      >
        <Box>
          <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
            Create Your Wallet First
          </Typography>
          <Typography
            mt={3}
            fontSize={16}
            lineHeight={1.75}
            color="raisinBlack"
          >
            To join Oily, you&apos;ll need a VeChain (VeWorld) or XDC (Metamask)
            wallet. It&apos;s quick and easy to set one up!
            <br />
            <br />
            Come back to create your Oily Account once you have your wallet
            ready.
          </Typography>
          <Box mt={11}>
            <Button
              variant="outlined"
              color="raisinBlack"
              onClick={() => handleToggleModal('createWallet')}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Stack>
  );
};
