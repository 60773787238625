import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {
  Stack,
  Typography,
  IconButton,
  Skeleton,
  Box,
  ButtonBase,
  useTheme,
  Modal,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { InfoIcon } from 'lucide-react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { apiGetTransactions } from '../../../apis/redeem';

export const TransactionItem = ({ item }) => {
  const theme = useTheme();

  return (
    <ButtonBase
      sx={{
        textAlign: 'left',
        px: 2,
        py: 1,
        borderRadius: 4,
        bgcolor: `${theme.palette.beauBlue}30`,
      }}
      component="a"
      href={item.transaction_link}
      target="_blank"
      rel="noreferrer noopener"
    >
      <Box flex={1}>
        <Typography fontSize={14} lineHeight={1.7} color="isabelline">
          {item.xp.toFixed(0)}XP {'->'}{' '}
          {Number(item.rewarded_amount).toFixed(2)} B3TR
        </Typography>
        <Typography fontSize={10} lineHeight={1.6} color="beauBlue">
          {dayjs(item.date).format('DD MMMM YYYY')}
        </Typography>
      </Box>
      <Typography fontSize={14} lineHeight={1.7} color="beauBlue">
        {item.status}
      </Typography>
    </ButtonBase>
  );
};

export const RedeemHistoryPart = () => {
  const {
    data: transactions,
    status,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['transactions'],
    queryFn: async ({ pageParam }) => {
      return await apiGetTransactions({
        skip: pageParam,
        limit: 100,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < 100) {
        return undefined;
      }
      return allPages.length * 100;
    },
  });

  const [showInfo, setShowInfo] = useState(false);

  const handleToggleModal = useCallback(() => {
    setShowInfo((value) => !value);
  }, []);

  return (
    <Stack mt={3} direction="column" spacing={1} px={3}>
      {transactions &&
        transactions.pages &&
        (transactions.pages.length === 0 ||
          transactions.pages[0].length === 0) && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography fontSize={18} color="beauBlue">
              No transactions yet
            </Typography>
            <IconButton
              size="small"
              color="isabelline"
              onClick={handleToggleModal}
            >
              <InfoIcon />
            </IconButton>
          </Stack>
        )}
      {status === 'pending' ? (
        Array(10)
          .fill(true)
          .map((item, index) => (
            <Skeleton variant="rounded" height={56} key={`loading-${index}`} />
          ))
      ) : (
        <>
          {transactions?.pages?.map((page, index) => (
            <React.Fragment key={`page-${index}`}>
              {page.map((item, ii) => (
                <TransactionItem item={item} key={`trx-${index}-${ii}`} />
              ))}
            </React.Fragment>
          ))}
          <Box className="mb-8 mt-8 flex flex-row justify-center">
            {hasNextPage ? (
              <LoadingButton
                variant="contained"
                color="icterine"
                loading={isFetchingNextPage}
                onClick={fetchNextPage}
              >
                Load more
              </LoadingButton>
            ) : null}
          </Box>
          {isFetchingNextPage &&
            Array(10)
              .fill(true)
              .map((item, index) => (
                <Skeleton
                  variant="rounded"
                  height={56}
                  key={`loading-more-${index}`}
                />
              ))}
        </>
      )}
      <Modal open={showInfo} onClose={handleToggleModal}>
        <Box>
          <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
            Thank you for Holding!
          </Typography>
          <Typography
            fontSize={16}
            lineHeight={1.75}
            color="raisinBlack"
            mt={3}
            component="div"
          >
            Better conversion rates & more utility are coming to Oily.
          </Typography>
          <Box mt={11}>
            <Button
              variant="outlined"
              color="raisinBlack"
              onClick={handleToggleModal}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Stack>
  );
};

TransactionItem.propTypes = {
  item: PropTypes.any,
};
