import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { CircleCheckIcon, CircleXIcon } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { apiGetRaffles } from '../../../apis/raffles';

import imgOilyHappy from '../../../assets/onboarding/oily-13.png';
import imgOilyStand from '../../../assets/onboarding/oily-11.png';
import imgOilySad from '../../../assets/onboarding/oily-12.png';
import imgConfetti from '../../../assets/backgrounds/confetti-bg.png';
import imgTransparentConfetti from '../../../assets/backgrounds/transparent-confetti-bg.png';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const RoundItem = ({ item }) => {
  const { account } = useSelector((state) => state.wallet);

  if (item.type === 'weekly') {
    return (
      <Box px={3}>
        <Box
          sx={{
            borderRadius: 4,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: item.me_voted ? 'green.500' : 'error.400',
            p: 2,
            ...(item.me_voted &&
            account === item.wallet_address &&
            item.transaction_id
              ? {
                  background: `url(${imgConfetti}) lightgray`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundColor: '#475985B2',
                }
              : {
                  bgcolor: item.me_voted ? 'purpleNavy' : 'transparent',
                }),
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box flex={1} mr={3}>
              <Typography fontSize={18} lineHeight={1.75} color="greyishBlue">
                <b>Round #{item.round}</b>
                {item.is_current_round ? ' (ongoing)' : ''}
              </Typography>
              {!item.transaction_id && !item.me_voted ? null : (
                <Typography
                  fontSize={18}
                  fontWeight={700}
                  lineHeight={1.75}
                  color={item.transaction_id ? 'green.400' : 'icterine'}
                >
                  {item.transaction_id ? (
                    <>
                      {item.wallet_address?.substr(0, 4)}...
                      {item.wallet_address?.substr(-4, 4)} won {item.amount}{' '}
                      B3TR
                    </>
                  ) : (
                    'WIN 100 $B3TR ON SUNDAY!'
                  )}
                </Typography>
              )}
              {item.me_voted &&
              account === item.wallet_address &&
              item.transaction_id ? (
                <Stack direction="row" alignItems="center" mt={2}>
                  <img
                    src={imgOilyHappy}
                    alt="oily"
                    style={{
                      width: 50,
                    }}
                  />
                  <Box flex={1} ml={2.5}>
                    <Typography
                      fontSize={14}
                      fontWeight={700}
                      lineHeight={1.75}
                      color="isabelline"
                    >
                      Congrats! YOU WON!
                    </Typography>
                    <Typography
                      fontSize={14}
                      lineHeight={1.75}
                      color="isabelline"
                    >
                      Your prize has been sent to your wallet
                    </Typography>
                  </Box>
                </Stack>
              ) : (
                <>
                  <Typography
                    mt={1.5}
                    fontSize={14}
                    lineHeight={1.75}
                    color="isabelline"
                  >
                    {item.me_voted
                      ? `Voted for Oily on ${dayjs(item.voted_at).format('MMM D')}`
                      : "You didn't vote for Oily"}
                  </Typography>
                  <Typography
                    fontSize={10}
                    lineHeight={1.6}
                    color={item.me_voted ? 'green.400' : 'error.400'}
                    display={
                      item.transaction_id && item.me_voted ? 'none' : 'block'
                    }
                  >
                    {item.me_voted
                      ? 'Eligible for Weekly Raffle'
                      : 'Not eligible for weekly draw'}
                  </Typography>
                </>
              )}
            </Box>
            <Box color={item.me_voted ? 'green.500' : 'error.500'}>
              {item.me_voted ? <CircleCheckIcon /> : <CircleXIcon />}
            </Box>
          </Stack>
        </Box>
      </Box>
    );
  }
  if (item.type === 'four_week') {
    return (
      <Box
        px={3}
        py={3}
        bgcolor="#1D1C27"
        mt="24px !important"
        mb="12px !important"
        sx={
          !item.wallet_address || item.wallet_address === account
            ? {
                backgroundImage: `url(${imgTransparentConfetti})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }
            : {}
        }
      >
        <Typography fontSize={32} lineHeight={1.25} color="isabelline">
          4-Week Raffle #{item.round}
        </Typography>
        <Typography
          fontSize={32}
          fontWeight={500}
          lineHeight={1.25}
          color="icterine"
        >
          {item.wallet_address === account
            ? 'You are the winner!'
            : 'Has a winner!'}
        </Typography>
        <Typography
          fontSize={18}
          fontWeight={700}
          lineHeight={1.75}
          color="green.400"
        >
          {item.wallet_address?.substr(0, 4)}...
          {item.wallet_address?.substr(-4, 4)} won {item.amount} B3TR
        </Typography>
        <Stack mt={2} direction="row" alignItems="center">
          <img
            src={
              item.wallet_address
                ? item.wallet_address === account
                  ? imgOilyHappy
                  : imgOilySad
                : imgOilyStand
            }
            alt="oily"
            style={{
              width: 60,
            }}
          />
          <Typography
            flex={1}
            ml={2.5}
            fontSize={16}
            lineHeight={1.75}
            color="isabelline"
          >
            {item.wallet_address === account ? (
              <>
                Your prize has been sent!
                <br />
                Thank you for voting Oily on&nbsp;
                <b>VeBetterDao</b>!
              </>
            ) : (
              <>
                Thank you for your support & keep voting Oily on&nbsp;
                <b>VeBetterDAO</b>!
              </>
            )}
          </Typography>
        </Stack>
      </Box>
    );
  }

  return null;
};

export const RafflesPart = () => {
  const username = useSelector((state) => state.profile.username);

  const { data, isLoading } = useQuery({
    queryKey: ['raffles'],
    queryFn: async () => {
      return await apiGetRaffles();
    },
  });

  const [current, setCurrent] = useState(dayjs());
  const [modals, setModals] = useState({
    guide: false,
  });

  const getDiff = useCallback((start, end) => {
    const diff = dayjs.duration(end.diff(start));
    return `${diff.days()} Day${diff.days() !== 1 ? 's' : ''}, ${diff.hours()} Hour${diff.hours() !== 1 ? 's' : ''}, and ${diff.minutes()} Minute${diff.minutes() !== 1 ? 's' : ''}`;
  }, []);

  const toggleModal = useCallback((name) => {
    setModals((value) => ({
      ...value,
      [name]: !value[name],
    }));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(dayjs());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box>
      <Box px={3} pt={3} pb={3}>
        <Typography
          fontSize={40}
          fontWeight={500}
          lineHeight={1.25}
          color="yellow.500"
        >
          Vote & Win
          <br />
          B3TR rewards!
        </Typography>
        <Typography
          fontSize={20}
          fontWeight={500}
          lineHeight={1.4}
          color="isabelline"
        >
          {username ? username : 'Hi'}, here&apos;s how you can participate and
          get chance to win B3TR rewards
        </Typography>
        <Box mt={4}>
          <Button
            variant="contained"
            color="isabelline"
            fullWidth
            onClick={() => toggleModal('guide')}
          >
            Learn more
          </Button>
        </Box>
      </Box>
      {isLoading ? (
        <Stack height={200} justifyContent="center" alignItems="center">
          <CircularProgress color="icterine" size={24} />
        </Stack>
      ) : (
        <Box
          px={3}
          mb={3}
          display={data?.is_in_current_raffle ? 'none' : 'block'}
        >
          <Box
            sx={{
              borderRadius: 4,
              p: 2,
              bgcolor: 'purpleNavy',
            }}
          >
            <Typography fontSize={18} lineHeight={1.75} color="greyishBlue">
              <b>Round #{data?.current_round}</b> (ongoing)
            </Typography>
            <Typography
              fontSize={18}
              fontWeight={700}
              lineHeight={1.75}
              color="yellow.500"
            >
              Win 100 B3TR!
            </Typography>
            <Typography fontSize={14} lineHeight={1.75} color="isabelline">
              Vote for Oily on this week&apos;s VeBetterDAO voting round and
              take part in the weekly raffle!
            </Typography>
            <Typography
              mt={2}
              fontSize={14}
              fontWeight={700}
              lineHeight={1.75}
              color="beauBlue"
            >
              Next weekly drawn in:
            </Typography>
            <Typography
              fontSize={20}
              fontWeight={600}
              color="isabelline"
              lineHeight={1.5}
            >
              {getDiff(current, dayjs(data?.round_end_datetime))}
            </Typography>
            <Box mt={3}>
              <Button
                variant="contained"
                color="icterine"
                fullWidth
                component="a"
                href={`https://governance.vebetterdao.org/rounds/${data?.current_round}`}
              >
                Vote Now
              </Button>
            </Box>
            <Typography
              mt={3}
              fontSize={14}
              fontWeight={700}
              color="green.400"
              lineHeight={1.75}
              textAlign="center"
            >
              Vote Oily every week for 4 weeks in a row to get a chance to win
              1000 $B3TR!
            </Typography>
            <Typography
              mt={3}
              fontSize={10}
              fontWeight={700}
              color="isabelline"
              lineHeight={1.6}
              textAlign="center"
            >
              Don&apos;t forget to check back results!
            </Typography>
          </Box>
        </Box>
      )}
      {data?.in_this_four_week_raffle ? (
        <Box
          px={3}
          py={3}
          mb={3}
          bgcolor="#1D1C27"
          sx={{
            backgroundImage: `url(${imgTransparentConfetti})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Typography fontSize={32} lineHeight={1.25} color="isabelline">
            You&apos;re In!
          </Typography>
          <Typography
            fontSize={32}
            fontWeight={500}
            lineHeight={1.25}
            color="icterine"
          >
            Win 1000 $B3TR!
          </Typography>
          <Stack mt={2} direction="row" alignItems="center">
            <img
              src={imgOilyHappy}
              alt="oily"
              style={{
                width: 60,
              }}
            />
            <Typography
              flex={1}
              ml={2.5}
              fontSize={16}
              lineHeight={1.75}
              color="isabelline"
            >
              You&apos;ve qualified for this month&apos;s raffle! Stay tuned for
              the winner announcement.
            </Typography>
          </Stack>
        </Box>
      ) : null}
      <Stack direction="column" spacing={1.5}>
        {data?.data?.map((item, index) => (
          <RoundItem key={`round-${index}`} item={item} />
        ))}
      </Stack>
      <Box px={3} mt={3}>
        <Typography
          mt={3}
          fontSize={40}
          fontWeight={500}
          lineHeight={1.25}
          color="isabelline"
        >
          Prize Information
        </Typography>
        <Typography mt={1.5} fontSize={14} lineHeight={1.75} color="isabelline">
          Vote in the VeBetterDAO weekly allocation round to participate, and
          prizes will be automatically sent to your wallet if you win. Users who
          vote for Oily for four consecutive weeks qualify for the monthly
          lottery with <b>a prize of 1000 B3TR!</b>
        </Typography>
      </Box>
      <Modal open={modals.guide} onClose={() => toggleModal('guide')}>
        <Box>
          <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
            How to participate
          </Typography>
          <Typography
            fontSize={16}
            lineHeight={1.75}
            color="raisinBlack"
            mt={3}
            component="div"
          >
            <ol
              style={{
                paddingInlineStart: 24,
              }}
            >
              <li>
                Vote in the Allocation Poll: Cast your vote on Oily in the
                current allocation poll on VeBetterDAO. This is your entry
                ticket to the raffle.
              </li>
              <li>
                Automatic Entry: Once your vote is submitted, you’re
                automatically entered into the raffle. No extra steps needed!
              </li>
              <li>
                Wait for the Results: Winners will be selected randomly after
                the voting period ends, and rewards will be distributed directly
                to your wallet.
              </li>
            </ol>
            <b>Note:</b> A 4-week voting streak on Oily puts you in the running
            for our 1000 $B3TR Super Raffle!
          </Typography>
          <Box mt={11}>
            <Button
              variant="outlined"
              color="raisinBlack"
              onClick={() => toggleModal('guide')}
            >
              Ok, I understand
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

RoundItem.propTypes = {
  item: PropTypes.any,
};
