import axios from 'axios';

export const apiGetRaffles = async () => {
  try {
    const response = await axios.get('/api/client/v2/user/rounds');
    const data = response.data.data;

    if (data.data[0]?.me_voted && data.current_round === data.data[0]?.round) {
      data.is_in_current_raffle = true;
    }

    if (data.data?.length > 0) {
      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].round === data.current_round) {
          data.data[i].is_current_round = true;
        }
      }
    }

    return data;
  } catch {
    return null;
  }
};
