import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { ArrowLeftIcon, InfoIcon } from 'lucide-react';
import { Scaffold } from '../../components/layouts/Scaffold';
import { XpIndicator } from '../../components/core/XpIndicator';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '@nfid/identitykit/react';
import { useQuery } from '@tanstack/react-query';
import { apiDisconnectWallet } from '../../apis/wallet';
import { apiLogin } from '../../apis/auth';
import { updateAppStatus } from '../../redux/app.slice';

import imgOily from '../../assets/onboarding/oily-14.png';
import imgBubble from '../../assets/onboarding/bubble-8.png';
import { apiGetProfile } from '../../apis/profile';

export const TelegramPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useAuth();

  const { account, chainName } = useSelector((state) => state.wallet);

  const { data } = useQuery({
    queryKey: ['telegram-status'],
    queryFn: async () => {
      return await apiLogin({
        wallet_address: account,
        chain: chainName,
      });
    },
    refetchInterval: 1000,
  });

  const [guide, setGuide] = useState(false);

  const handleBack = useCallback(async () => {
    if (chainName === 'nfid') {
      await auth.disconnect();
    }
    apiDisconnectWallet();
    navigate('/welcome', {
      replace: true,
    });
  }, [navigate, auth, chainName]);

  const handleContinue = useCallback(() => {
    if (location.state?.joinTelegramLink) {
      window.open(location.state.joinTelegramLink, '_blank');
    }
  }, [location]);

  const handleToggleGuide = useCallback(() => {
    setGuide((value) => !value);
  }, []);

  useEffect(() => {
    if (location.state?.joinTelegramLink) {
      return;
    } else {
      navigate('/welcome', {
        replace: true,
      });
    }
  }, [navigate, location]);

  useEffect(() => {
    if (data === 'verified') {
      dispatch(
        updateAppStatus({
          isGlobalLoading: true,
        }),
      );
      apiLogin({
        wallet_address: account,
        chain: chainName,
      }).then(async () => {
        await apiGetProfile({
          need_balance: true,
          need_ratio: true,
        });
        navigate('/onboarding/congrats-2');
        dispatch(
          updateAppStatus({
            isGlobalLoading: false,
          }),
        );
      });
    }
  }, [data, dispatch, navigate, account, chainName]);

  return (
    <Scaffold>
      <Scaffold.Header>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={1}
          minHeight={56}
          pt={1}
        >
          <IconButton color="isabelline" sx={{ mr: 2 }} onClick={handleBack}>
            <ArrowLeftIcon />
          </IconButton>
          <XpIndicator />
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Stack
          height="100%"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box position="relative">
            <img
              src={imgOily}
              alt="Oily"
              style={{
                width: 206,
                height: 225,
              }}
            />
            <img
              src={imgBubble}
              alt="Oily"
              style={{
                position: 'absolute',
                left: -30,
                top: -160,
                width: 285,
                height: 189,
              }}
            />
          </Box>
        </Stack>
      </Scaffold.Content>
      <Scaffold.Footer>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          mb={3}
        >
          <Typography fontSize={18} lineHeight={1} color="icterine">
            Show me how
          </Typography>
          <IconButton size="small" color="icterine" onClick={handleToggleGuide}>
            <InfoIcon />
          </IconButton>
        </Stack>
        <Stack direction="row" justifyContent="center" px={3} pb={3}>
          <Button variant="contained" color="icterine" onClick={handleContinue}>
            Get access
          </Button>
        </Stack>
      </Scaffold.Footer>
      <Modal open={guide} onClose={handleToggleGuide}>
        <Box>
          <Typography fontSize={32} lineHeight={1.25} color="raisinBlack">
            Here&apos;s how to join Oily Telegram:
          </Typography>
          <Typography
            fontSize={16}
            lineHeight={1.75}
            color="raisinBlack"
            mt={3}
            component="div"
          >
            <ol
              style={{
                paddingInlineStart: 24,
              }}
            >
              <li>Connect your Telegram account</li>
              <li>Get your unique Oily code</li>
              <li>
                Validate it here to unlock your access your Telegram account
              </li>
            </ol>
          </Typography>
          <Box mt={11}>
            <Button
              variant="outlined"
              color="raisinBlack"
              onClick={handleToggleGuide}
            >
              I understand
            </Button>
          </Box>
        </Box>
      </Modal>
    </Scaffold>
  );
};
