import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ArrowLeftIcon } from 'lucide-react';
import { Scaffold } from '../../components/layouts/Scaffold';
import { SelectChoiceGroup } from '../../components/inputs/SelectChoiceGroup';
import { useNavigate, useLocation } from 'react-router-dom';

const data = [
  {
    question: 'Do you use cooking oil to prepare your food?',
    answers: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  {
    question:
      'How many liters of used cooking oil do you estimate you produce per month?',
    answers: [
      { value: 'lt_1', label: '0 ~ 1L' },
      { value: '1_5', label: '1L ~ 5L' },
      { value: '5_10', label: '5L ~ 10L' },
      { value: 'gt_10', label: '+10L' },
    ],
  },
  {
    question: 'Do you recycle your used cooking oil?',
    answers: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  {
    question:
      'Did you know one drop of used cooking oil can pollute up to 1 million liters of water?',
    answers: [
      { value: true, label: "Yes, that's crazy" },
      { value: false, label: 'I had no idea' },
    ],
  },
];

export const QuestionsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [step, setStep] = useState(0);
  const [values, setValues] = useState([null, null, null, null]);
  const [saving, setSaving] = useState(false);

  const handleSelectChoice = useCallback(
    (value) => {
      setValues((currentValues) => {
        const newValues = [...currentValues];
        newValues[step] = value;
        return newValues;
      });
    },
    [step],
  );

  const handleContinue = useCallback(async () => {
    if (values[step] === null) {
      return;
    }

    if (step < 3) {
      setStep(step + 1);
    } else {
      setSaving(true);
      setTimeout(() => {
        setSaving(false);
        navigate('/onboarding/congrats-1', {
          replace: true,
          state: {
            joinTelegramLink: location.state?.joinTelegramLink,
          },
        });
      }, 1000);
    }
  }, [navigate, values, step, location]);

  useEffect(() => {
    if (location.state?.joinTelegramLink) {
      return;
    }
    window.location.href = '/welcome';
  }, [location]);

  return (
    <Scaffold>
      <Scaffold.Header>
        <Stack direction="row" alignItems="center" px={3} minHeight={56}>
          <IconButton color="isabelline" size="small" sx={{ mr: 2 }}>
            <ArrowLeftIcon />
          </IconButton>
          <LinearProgress
            variant="determinate"
            value={(step + 1) * 25}
            sx={{ flex: 1 }}
          />
          <Typography color="isabelline" fontSize={12} sx={{ ml: 3 }}>
            {step + 1} of 4
          </Typography>
        </Stack>
      </Scaffold.Header>
      <Scaffold.Content>
        <Box px={3} py={2}>
          <Typography fontSize={32} color="isabelline">
            {data[step].question}
          </Typography>
          <Box mt={2}>
            <SelectChoiceGroup
              value={values[step]}
              options={data[step].answers}
              onChange={handleSelectChoice}
            />
          </Box>
        </Box>
      </Scaffold.Content>
      <Scaffold.Footer>
        <Stack direction="row" justifyContent="center" px={3} pb={3}>
          <LoadingButton
            variant="contained"
            color="icterine"
            loading={saving}
            onClick={handleContinue}
          >
            Continue
          </LoadingButton>
        </Stack>
      </Scaffold.Footer>
    </Scaffold>
  );
};
