import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {
  Stack,
  Typography,
  Skeleton,
  Box,
  ButtonBase,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useInfiniteQuery } from '@tanstack/react-query';
import { apiGetXpHistory } from '../../../apis/redeem';

export const LogItem = ({ item }) => {
  const theme = useTheme();

  return (
    <ButtonBase
      sx={{
        textAlign: 'left',
        px: 2,
        py: 1,
        borderRadius: 4,
        bgcolor: `${theme.palette.beauBlue}30`,
      }}
      component="a"
      href={item.transaction_link}
      target="_blank"
      rel="noreferrer noopener"
    >
      <Box flex={1}>
        <Typography
          fontSize={14}
          lineHeight={1.7}
          color="isabelline"
          sx={{
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            wordBreak: 'break-all',
          }}
        >
          {item.type}
        </Typography>
        <Typography fontSize={10} lineHeight={1.6} color="beauBlue">
          {dayjs(item.earned_at).format('DD MMMM YYYY')}
        </Typography>
      </Box>
      <Typography
        fontSize={14}
        fontWeight={400}
        lineHeight={1}
        color={item.xp > 0 ? 'green.500' : 'error.500'}
        mx={2}
      >
        {item.xp.toFixed(0)} XPs
      </Typography>
      <Typography fontSize={14} lineHeight={1.7} color="beauBlue">
        {item.status}
      </Typography>
    </ButtonBase>
  );
};

export const XpHistoryPart = () => {
  const {
    data: logs,
    status,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['xp-history'],
    queryFn: async ({ pageParam }) => {
      return await apiGetXpHistory({
        skip: pageParam,
        limit: 100,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < 100) {
        return undefined;
      }
      return allPages.length * 100;
    },
  });

  return (
    <Stack mt={3} direction="column" spacing={1} px={3}>
      {logs &&
        logs.pages &&
        (logs.pages.length === 0 || logs.pages[0].length === 0) && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography fontSize={18} color="beauBlue">
              No history yet
            </Typography>
          </Stack>
        )}
      {status === 'pending' ? (
        Array(10)
          .fill(true)
          .map((item, index) => (
            <Skeleton variant="rounded" height={56} key={`loading-${index}`} />
          ))
      ) : (
        <>
          {logs?.pages?.map((page, index) => (
            <React.Fragment key={`page-${index}`}>
              {page.map((item, ii) => (
                <LogItem item={item} key={`log-${index}-${ii}`} />
              ))}
            </React.Fragment>
          ))}
          <Box className="mb-8 mt-8 flex flex-row justify-center">
            {hasNextPage ? (
              <LoadingButton
                variant="contained"
                color="icterine"
                loading={isFetchingNextPage}
                onClick={fetchNextPage}
              >
                Load more
              </LoadingButton>
            ) : null}
          </Box>
          {isFetchingNextPage &&
            Array(10)
              .fill(true)
              .map((item, index) => (
                <Skeleton
                  variant="rounded"
                  height={56}
                  key={`loading-more-${index}`}
                />
              ))}
        </>
      )}
    </Stack>
  );
};

LogItem.propTypes = {
  item: PropTypes.any,
};
